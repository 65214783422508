import React from 'react';
import logo from './logo.svg';
import logo2 from './image2.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <img src={ window.location.hostname.includes("blobber.ch") ? logo : logo2 } className="App-logo" alt="logo" />
        <p>
            { window.location.hostname } is currently unavailable. 
        </p>
          <small className="App-small">Questions? Contact us:<br/>contact@{ window.location.hostname }</small>
      </header>
    </div>
  );
}

export default App;
